import React from 'react'
import { Link } from 'gatsby'
import { Masthead, theme } from '@roar/components'
import { mapperWithFunction } from '../common/utils'

const MASTHEAD_MAP = {
  headline: ['headline'],
  date: ['date'],
  meta: ['meta'],
  to: ['to'],
  postType: ['postType'],
  eyebrow: ['eyebrow'],
}

const MastheadWrapper = (props) => {
  const { headline, date = '', eyebrow } = mapperWithFunction(props, MASTHEAD_MAP)

  return <Masthead eyebrow={eyebrow} headline={headline} date={date} bg={'brandBeige'} color={'textDark'} />
}

export default MastheadWrapper
